<template>
  <figure v-lazyload class="image__wrapper">
    <img
        class="image__item"
        :data-url="sourceThumb"
        alt="random imagee"
        @click="$emit('imageClick', $event, sourceOriginal)"
        @mousedown="$event.preventDefault()"
    >
  </figure>
</template>

<script>

export default {
  name: "ImageItem",
  props: {
    sourceThumb: {
      type: String,
      required: true
    },
    sourceOriginal: {
      type: String,
      required: true
    },
  }
};
</script>

<style scoped>
.image__wrapper {
  margin: 0;
  padding: 0;
}

.image__wrapper.loaded .image__item {
  visibility: visible;
  opacity: 1;
  border: 0;
}

.image__item {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.4s ease-in-out;
  opacity: 0;
  visibility: hidden;
}
</style>
