import Vue from 'vue'
import './assets/theme/theme/index.css'
import App from './App.vue'
import Element from 'element-ui'
import locale from 'element-ui/lib/locale/lang/en'
import LazyLoadDirective from "./directives/LazyLoadDirective";

Vue.directive("lazyload", LazyLoadDirective);
locale.el.colorpicker.confirm = 'Übernehmen'
locale.el.colorpicker.clear = 'Abbrechen'
Vue.use(Element, { locale })

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
