const fabric = require('./text/FabricText.js')

/// increase digits for scale for serializing
fabric.Object.NUM_FRACTION_DIGITS = 5;

/// utils
fabric.Canvas.prototype.uuidv4 = function () {

    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });

}

// pan zoom
fabric.Canvas.prototype.on('mouse:wheel', function (opt) {
    if (opt.e.ctrlKey === true) {
        const delta = Math.sign(opt.e.deltaY)
        let zoom = this.getZoom()
        const step = 12
        zoom = zoom - delta / step
        if (zoom > 4) zoom = 4
        if (zoom < 0.2) zoom = 0.2
        this.zoomToPoint({
            x: opt.e.offsetX,
            y: opt.e.offsetY
        }, zoom)
        this.fire('zoom:changed')
    } else {
        this.viewportTransform[5] -= opt.e.deltaY / 2
        this.viewportTransform[4] -= opt.e.deltaX / 2
    }
    this.calcOffset()
    this.calcViewportBoundaries()
    this.getObjects().forEach(object => {
        object.setCoords()
    })
    this.requestRenderAll()
    opt.e.preventDefault()
    opt.e.stopPropagation()
})

fabric.Canvas.prototype.on('mouse:down', function (opt) {
    const evt = opt.e

    var g3 = evt.buttons == 1 && ((this.scrollbars.viewportheight - evt.clientY) < 30 || (this.scrollbars.viewportwidth - evt.clientX) < 30);

    if (evt.which === 2 || g3) {
        this.isDragging = true
        this.selection = false
        this.requestRenderAll()
    } else {
        this.isDragging = false
        this.selection = true
    }
    this.calcViewportBoundaries()
    this.lastPosX = evt.clientX
    this.lastPosY = evt.clientY
    this.lastViewportTransform = {
        x: this.viewportTransform[4],
        y: this.viewportTransform[5]
    }
})

fabric.Canvas.prototype.on('mouse:move', function (opt) {

    const e = opt.e

    if (this.isDragging) {
        this.viewportTransform[4] += e.clientX - this.lastPosX
        this.lastPosX = e.clientX
        this.viewportTransform[5] += e.clientY - this.lastPosY
        this.lastPosY = e.clientY
        this.calcViewportBoundaries()
        this.requestRenderAll()
        this.fire('zoom:changed')
        opt.e.preventDefault()
        opt.e.stopPropagation()
    } else {
        if (this.isDragging) {
            this.isDragging = false
            this.selection = true
        }
    }
})

fabric.Canvas.prototype.on('mouse:up', function () {
    this.isDragging = false
    this.selection = true
    this.getObjects().forEach(object => {
        object.setCoords()
    })
})


fabric.Canvas.prototype._historyNext = function () {
    return JSON.stringify(this.toJSON(['evented', 'role', 'selectable', 'imgid']))
}
fabric.Canvas.prototype._historyEvents = function () {
    return {
        'object:added': this._historySaveAction,
        'object:removed': this._historySaveAction,
        'object:modified': this._historySaveAction,
        'object:skewing': this._historySaveAction
    }
}

fabric.Canvas.prototype._historySaveAction = function () {
    if (this.historyProcessing)
        return
    const json = this.historyNextState
    this.historyUndo.push(json)
    this.historyNextState = this._historyNext()
    this.fire('history:append', {
        json: json
    })
}
fabric.Canvas.prototype.undo = function (callback) {
    this.historyProcessing = true
    const history = this.historyUndo.length > 1 ? this.historyUndo.pop() : null
    if (history) {
        this.historyRedo.push(this._historyNext())
        this.historyNextState = history
        this._loadHistory(history, 'history:undo', callback)
    } else {
        this.historyProcessing = false
    }
}

fabric.Canvas.prototype.redo = function (callback) {
    this.historyProcessing = true
    const history = this.historyRedo.pop()
    if (history) {
        this.historyUndo.push(this._historyNext())
        this.historyNextState = history
        this._loadHistory(history, 'history:redo', callback)
    } else {
        this.historyProcessing = false
    }
},
    fabric.Canvas.prototype._loadHistory = function (history, event, callback) {
        this.loadFromJSON(history, () => {
            this.renderAll()
            this.fire(event)
            this.historyProcessing = false

            if (callback && typeof callback === 'function')
                callback()
        })
    }
fabric.Canvas.prototype.historyUndo = []
fabric.Canvas.prototype.historyRedo = []
fabric.Canvas.prototype.historyNextState = fabric.Canvas.prototype._historyNext()
fabric.Canvas.prototype.on(fabric.Canvas.prototype._historyEvents())

fabric.Canvas.prototype.minZoom = 0.25
fabric.Canvas.prototype.zoomIn = function () {
    this.zoomToPoint({
        x: this.getCenter().left,
        y: this.getCenter().top
    }, this.getZoom() * 1.1)
    this.getObjects().forEach(object => {
        object.setCoords()
    })
    this.fire('zoom:changed')
    this.requestRenderAll()
    return this
}
fabric.Canvas.prototype.zoomOut = function () {
    this.zoomToPoint({
        x: this.getCenter().left,
        y: this.getCenter().top
    }, this.getZoom() * 0.9)
    this.getObjects().forEach(object => {
        object.setCoords()
    })
    this.fire('zoom:changed')
    this.requestRenderAll()
    return this
}
fabric.Canvas.prototype.zoomTo = function (value) {
    this.zoomToPoint({
        x: this.getCenter().left,
        y: this.getCenter().top
    }, value)
    this.getObjects().forEach(object => {
        object.setCoords()
    })
    this.fire('zoom:changed')
    this.requestRenderAll()
    return this
}

fabric.Canvas.prototype.addText = function (options) {
    let interactiveText = new fabric.InteractiveText({
        textMarkup: options.text
    })
    interactiveText.transformTextMarkup()
    interactiveText.setStyles({
        fill: '#000000',
        fontSize: Number(options.fontSize),
        fontFamily: this._defaultFont
    })
    interactiveText.computeLayout()
    interactiveText.set({
        left: this._page ? this._page.getCenterPoint().x - interactiveText.width * interactiveText.scaleX / 2 : 0,
        top: this._page ? this._page.getCenterPoint().y - interactiveText.height * interactiveText.scaleY / 2 : 0
    })
    this.add(interactiveText)
    this.setActiveObject(interactiveText)
    interactiveText.enterEditing()
    interactiveText.setCursorPosition(interactiveText.getTextLength())
    this.requestRenderAll()
}
fabric.Canvas.prototype.addClipart = function (url) {
    fabric.Image.fromURL(url, (img) => {
        let size = Math.min(this._page.width * this._page.scaleX, this._page.height * this._page.scaleY) / 5
        img.scaleToWidth(size)
        img.set({
            left: this._page ? this._page.getCenterPoint().x - size / 2 : 0,
            top: this._page ? this._page.getCenterPoint().y - size / 2 : 0
        })
        this.add(img)
        img.setCoords()
        this.setActiveObject(img)
    })
}

fabric.Canvas.prototype.resizeBase64Img = function (base64, max) {
    return new Promise((resolve) => {
        let img = document.createElement("img");
        img.src = base64;
        img.onload = function () {

            if (img.width <= max && img.height <= max) {
                console.log("nothing to shrinky");
                resolve(base64);
                return;
            }

            var ratio = img.width / img.height;
            var newW = max;
            var newH = newW / ratio;
            if (newH > max) {
                newH = max;
                newW = newH * ratio;
            }
            var canvas = document.createElement("canvas");
            canvas.width = newW;
            canvas.height = newH;
            let context = canvas.getContext("2d");
            context.scale(newW / img.width, newH / img.height);
            context.drawImage(img, 0, 0);
            resolve(canvas.toDataURL('image/png', 0.1));
        }
    });
}

fabric.Canvas.prototype.addImage = function (base64, name, ix = 0, callback = null) {

    var max = 750;
    var that = this;

    var imgId = this.uuidv4();

    /// pass "original" img to host
    if (callback) {
        new Promise(function (resolve) {
            callback(imgId, base64)
            resolve()
        });
    }

    /// resize, then add
    this.resizeBase64Img(base64, max).then(function (res) { 

        fabric.Image.fromURL(res, (img) => {
            img.imgid = imgId;
            let sizeMin = Math.min(that._page.width * that._page.scaleX, that._page.height * that._page.scaleY) / 1
            let sizeMax = Math.max(that._page.width * that._page.scaleX, that._page.height * that._page.scaleY) / 1
            let size = that._page.width < that._page.height ? sizeMin : sizeMax; /* hack. but this scales it to the width of the doc also in ladscape. good enough */
            img.scaleToWidth(size)
            img.set({
                left: that._page ? that._page.getCenterPoint().x - img.width * img.scaleX / 2 : 0,
                top: that._page ? that._page.getCenterPoint().y - img.height * img.scaleY / 2 : 0
            })
            if (name) {
                img.name = name;
                that.getObjects().forEach(object => {
                    if (object.name == name) {
                        that.remove(object)
                        that.discardActiveObject()
                        that.requestRenderAll()
                        return false;
                    }
                })
            }
            that.add(img)
            if (ix > 0) {
                var ii = that._objects.indexOf(img);
                that._objects.splice(ix, 0, that._objects.splice(ii, 1)[0]);
            }
            img.setCoords()
            that.setActiveObject(img)
        })

    });

    return imgId;
}
fabric.Canvas.prototype.addSvg = function (base64) {
    fabric.loadSVGFromURL(base64, (objects, options) => {
        var svgData = fabric.util.groupSVGElements(objects, options)
        let sizeMin = Math.min(this._page.width * this._page.scaleX, this._page.height * this._page.scaleY) / 5
        let sizeMax = Math.max(this._page.width * this._page.scaleX, this._page.height * this._page.scaleY) / 5
        let size = this._page.width < this._page.height ? sizeMin : sizeMax; /* hack. but this scales it to the width of the doc also in ladscape. good enough */
        /// console.log("size", size);
        svgData.scaleToWidth(size)
        svgData.set({
            left: this._page ? this._page.getCenterPoint().x - size / 2 : 0,
            top: this._page ? this._page.getCenterPoint().y - size / 2 : 0
        })
        this.add(svgData)
        svgData.setCoords()
        this.setActiveObject(svgData)
    })
}
fabric.Canvas.prototype.addCircle = function () {
    let size = Math.min(this._page.width * this._page.scaleX, this._page.height * this._page.scaleY) / 10
    let circle = new fabric.Circle({
        radius: size,
        fill: '#000000',
        strokeWidth: 0,
        strokeUniform: true,
        left: this._page ? this._page.getCenterPoint().x - size : 0,
        top: this._page ? this._page.getCenterPoint().y - size : 0
    })
    this.add(circle)
    this.setActiveObject(circle)
}
fabric.Canvas.prototype.addTriangle = function () {
    let size = Math.min(this._page.width * this._page.scaleX, this._page.height * this._page.scaleY) / 5
    let triangle = new fabric.Triangle({
        width: size,
        height: size,
        fill: '#000000',
        strokeWidth: 0,
        strokeUniform: true,
        left: this._page ? this._page.getCenterPoint().x - size / 2 : 0,
        top: this._page ? this._page.getCenterPoint().y - size / 2 : 0
    })
    this.add(triangle)
    this.setActiveObject(triangle)
}
fabric.Canvas.prototype.addRect = function () {
    let size = Math.min(this._page.width * this._page.scaleX, this._page.height * this._page.scaleY) / 5
    let rect = new fabric.Rect({
        width: size,
        height: size,
        fill: '#000000',
        strokeWidth: 0,
        strokeUniform: true,
        left: this._page ? this._page.getCenterPoint().x - size / 2 : 0,
        top: this._page ? this._page.getCenterPoint().y - size / 2 : 0
    })
    this.add(rect)
    this.setActiveObject(rect)
}
fabric.Canvas.prototype.addEllipse = function () {
    let size = Math.min(this._page.width * this._page.scaleX, this._page.height * this._page.scaleY) / 10
    let ellipse = new fabric.Ellipse({
        rx: size,
        ry: size * 0.6,
        fill: '#000000',
        strokeWidth: 0,
        strokeUniform: true,
        left: this._page ? this._page.getCenterPoint().x - size : 0,
        top: this._page ? this._page.getCenterPoint().y - size : 0
    })
    this.add(ellipse)
    this.setActiveObject(ellipse)
}
fabric.Canvas.prototype.addPath = function (pathstring) {
    let size = Math.min(this._page.width * this._page.scaleX, this._page.height * this._page.scaleY) / 3
    var path = new fabric.Path(pathstring, {
        strokeWidth: 0,
        strokeUniform: true,
        stroke: '#000000',
        fill: '#000000',
        left: this._page ? this._page.getCenterPoint().x - (size / 2) : 0,
        top: this._page ? this._page.getCenterPoint().y - (size / 2) : 0
    });
    var scale = size / path.width;
    path.scale(scale);
    this.add(path)
    this.setActiveObject(path)
}
// experimental && temp

fabric.Canvas.prototype.selectionBorderColor = '#3d87a6'
fabric.Canvas.prototype.selectionColor = 'rgba(11, 171, 100, 0.1)'
fabric.Object.prototype.borderColor = '#3d87a6'
fabric.Object.prototype.borderOpacityWhenMoving = 1
fabric.Object.prototype.cornerColor = '#3d87a6'
fabric.Object.prototype.cornerStyle = 'circle'
fabric.Object.prototype.transparentCorners = false
fabric.Control.prototype.cursorStyle = `url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAQAAABKfvVzAAAAB3RJTUUH3QYBCzMMpADxbgAAAAlwSFlzAAALEgAACxIB0t1+/AAAAARnQU1BAACxjwv8YQUAAAJvSURBVHjaXVRLaxNRFP7mkZjGUqRWJUZbX9AEikK1QtEsdCFIULLygdCN9BcIgkhBXKoIKr5wo7hyo1FcSBaiohFfO7WIQlAXolKL0po2k5nxu+femUw9l/s833mfGQsdsjgC7t1Yjz50YRqf8D3xHu2GbF6BEVzFJGYRcnj4gnvYj0WGayeUy2U5rsMXqI8W5tHmKeB8jM2C6UJZhA18hA6EBLVEJBot2gkxgzFCb+MZxA2beoZQQ47sFF/ep+vuz9Bu93ujWEN+Gy5+4zM24j72aJFuvKSeec5Gz8HdWaBg9fJ925LFhxmHctEXB+8odxzOYwb+Ir9asmFTpwvbpdDaHD7Ert7SESxFQ/xt9Azw5vZbIqKzlsIEmnTZwxwRN7TAPgkuxFEB6IrIutPFJZOpUIK/SX1klCRwH3XuvkJWrOowrMqbqo0LQxe/rZhb2coFy/x1eK4t1ETDNDbE5TslL6djWwsojdfC/oFVJgVFSYBys6hiohKHq6OV2ZI0RYHZwfD+yj7Lk3oPJA5fdqGqPMyweJDsAOP4hSmuMP1jxSehE6YKY9BJIBXyhXyiyzZhS1Jku7gV4onpy4hlm5hS5DRxKE5BxcIjU4kzAnMSAxUHV0wVzmkRpaMsxVdWTiLTKRznIB6IfeXy2ci2Ws6LDSXyFsexC8MYxQFcY+hRH9V3sCkzSkkfl3KG3a4Me+Y7aJqT6iIF/8jPNhE2RUpZXBZIm9lvG6g6qew/tAYWJhbmi92LV3Gzheb0FUe2ZjtwKyHCf0Ilc7cUqgh6Cf2Dd3harE1OCTj4X0CHr/SqRKd586oa5KQCL4wg/wA4WvtpoKznTAAAAABJRU5ErkJggg==) 12 12, auto`
fabric.ActiveSelection.prototype.borderColor = '#3d87a6'
fabric.ActiveSelection.prototype.borderOpacityWhenMoving = 1
fabric.ActiveSelection.prototype.cornerColor = '#3d87a6'
fabric.ActiveSelection.prototype.cornerStyle = 'circle'
fabric.ActiveSelection.prototype.transparentCorners = false


// page functionality
fabric.Canvas.prototype.addPage = function (options, callback) {
    this._createObjectPage(options, (page) => {
        callback && callback({
            ...options,
            json: {
                objects: [page]
            }
        })
    })
}
fabric.Canvas.prototype._createObjectPage = function (options, callback) {
    //console.log("addPage options", options)
    //console.log("addPage options svg", options.svg)
    if (options.svg && options.svg.startsWith('<svg')) {
        fabric.loadSVGFromString(options.svg, (objects, opt) => {
            if (options.fill) {
                objects.forEach(object => {
                    object.set({ fill: options.fill, stroke: null })
                })
            }
            let page = fabric.util.groupSVGElements(objects, opt)
            page.scaleX = Number(options.width) / (page.width * page.scaleX)
            page.scaleY = Number(options.height) / (page.height * page.scaleY)
            page.role = 'page'
            page.evented = false
            page.selectable = false
            callback && callback(page.toJSON(['evented', 'role', 'selectable', 'imgid']))
        })
    }
    else if (options.svg) {
        fabric.loadSVGFromURL(options.svg, (objects, opt) => {
            if (options.fill) {
                objects.forEach(object => {
                    object.set({ fill: options.fill, stroke: null })
                })
            }
            let page = fabric.util.groupSVGElements(objects, opt)
            page.scaleX = Number(options.width) / (page.width * page.scaleX)
            page.scaleY = Number(options.height) / (page.height * page.scaleY)
            page.role = 'page'
            page.evented = false
            page.selectable = false
            callback && callback(page.toJSON(['evented', 'role', 'selectable', 'imgid']))
        })
    } else {
        let page = new fabric.Rect({
            left: 0,
            top: 0,
            role: 'page',
            width: Number(options.width),
            height: Number(options.height),
            fill: options.fill || '#ffffff',
            evented: false,
            selectable: false,
        })
        callback && callback(page.toJSON(['evented', 'role', 'selectable', 'imgid']))
    }
}
fabric.Canvas.prototype.loadPage = function (json, callback) {
    this.loadFromJSON(json, () => {
        this.viewportTransform[4] = 0
        this.viewportTransform[5] = 0
        this.historyUndo = []
        this.historyRedo = []
        this.centerPage()
        this.getObjects().forEach(object => {
            if (object.role === 'page') {
                this._page = object
            }
        })
        if (this._page && this._page.type !== 'rect') {
            this.getObjects().forEach(object => {
                if (object.role === 'background') {
                    this.remove(object);
                }
            })
        }
        /// this.recalculateBackgroudImageSize();
        if (this.getMissingFonts().length > 0) {
            this.fire('missing:fonts', this.getMissingFonts())
        }
        this.renderAll()
        callback && callback()
    })
}

//fabric.Canvas.prototype.recalculateBackgroudImageSize = function () {
//    let that = this;
//    this.getObjects().forEach(object => {
//        if (object.role === 'background') {
//            /// just reset the scale to increase precision. this is a hack to fix the fact that the 
//            /// serialized fabric js has low precision. was unable to figure out how to increase precision
//            let scale = (that._page.height + fabric.Canvas.backgroundMarginPx) / object.height;
//            object.scale(scale);
//        }
//    })
//}

fabric.Canvas.backgroundMarginPx = 2;
fabric.Canvas.prototype.loadPageBackgroundImage = function (imageUrl, callback) {
    let that = this;
    this.getObjects().forEach(object => {
        if (object.role === 'background') {
            this.remove(object);
        }
    })

    fabric.Image.fromURL(imageUrl, (img) => {
        img.scaleToHeight(that._page.height + fabric.Canvas.backgroundMarginPx);

        /// this was disabled. i enabled and it works. might have side effects...
        if (that._page.width > img.width * img.scaleX)
            img.scaleToWidth(that._page.width);


        let clipPath = new fabric.Rect({
            left: Math.floor(-that._page.width / img.scaleX / 2.0),
            top: Math.floor(-that._page.height / img.scaleY / 2.0),
            width: Math.ceil(that._page.width / img.scaleX),
            height: Math.ceil(that._page.height / img.scaleY),
            strokeWidth: 0,
        })

        img.set({
            left: (that._page.width - img.width * img.scaleX) / 2.0,
            top: (that._page.height - img.height * img.scaleY) / 2.0,
            role: 'background',
            selectable: false,
            evented: false,
            clipPath: clipPath,
            strokeWidth: 0,
        });

        that.add(img);
        that.setActiveObject(img);
        that.positionActiveObject('back');
        that.discardActiveObject();

        callback();
    });
}

// load font
let base64Font = require('./text/default-font.js')

let _base64ToArrayBuffer = (base64) => {
    if (!(window || {}).atob) return
    let binary_string = window.atob(base64)
    let len = binary_string.length
    let bytes = new Uint8Array(len)
    for (let i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i)
    }
    return bytes.buffer
}

fabric.Canvas.prototype.loadDefaultFont = function () {
    let buffer = _base64ToArrayBuffer(base64Font)
    if (!buffer) return
    let name = 'OpenSans-Regular'
    this._defaultFont = name
    this.loadFont(buffer, name)
}

fabric.Canvas.prototype.loadFont = function (buffer, name) {
    let fObject = fabric.Typr.parse(buffer)[0]
    if (fabric.fontArrayList.some(font => font.fontName === name)) return
    fabric.fontArrayList.push({
        FontName: name,
        fObject: fObject
    })
    this.getObjects().forEach(object => {
        if (object.type === 'InteractiveText') {
            object.computeLayout()
        }
    })
}
fabric.Canvas.prototype.getMissingFonts = function () {
    let missingFonts = []
    this.getObjects().forEach(object => {
        if (object.type === 'InteractiveText') {
            if (object._styleMap) {
                object._styleMap.forEach(style => {
                    if (!fabric.fontArrayList.some(font => font.FontName === style.fontFamily)) {
                        if (!missingFonts.some(missingFont => missingFont === style.fontFamily)) {
                            missingFonts.push(style.fontFamily)
                        }
                    }
                })
            }
        }
    })
    return missingFonts
}
fabric.Canvas.prototype.fontIsLoaded = function (name) {
    console.log(name)
}
fabric.Canvas.prototype.centerPage = function () {
    this.getObjects().forEach(object => {
        if (object.role === 'page') {
            this.setZoom(1)
            this.viewportTransform[4] = object.left + this.width / 2 - object.width * object.scaleX / 2
            this.viewportTransform[5] = object.top + this.height / 2 - object.height * object.scaleY / 2
            this.zoomToPoint({
                x: this.getCenter().left,
                y: this.getCenter().top
            }, Math.min(this.width / (object.width * object.scaleX), this.height / (object.height * object.scaleY)) * 0.65)
            this.fire('zoom:changed')
            this.requestRenderAll()
        }
    })
}

fabric.Canvas.prototype.getTab = function (object) {
    if (object.type === 'InteractiveText') {
        return 'text'
    }
    if (object.type === 'image') {
        return 'images'
    }
    if (object.type !== 'activeSelection' && object.type !== 'group') {
        return 'shapes'
    }
    return 'shapes'
}

fabric.Canvas.prototype.initResponsive = function (container) {
    this.setWidth(Math.floor(container.offsetWidth))
    this.setHeight(Math.floor(container.offsetHeight))
    this.renderAll()
    const step = () => {
        setTimeout(() => {
            if (container) {
                const containerWidth = Math.floor(container.offsetWidth)
                const containerHeight = Math.floor(container.offsetHeight)
                if (Math.abs(containerWidth - this.width) > 2) {
                    this.setWidth(1)
                    setTimeout(() => {
                        this.setWidth(containerWidth).renderAll()
                        this.centerPage()
                    }, 1)
                }
                if (Math.abs(containerHeight - this.height) > 2) {
                    this.setHeight(1)
                    setTimeout(() => {
                        this.setHeight(containerHeight).renderAll()
                        this.centerPage()
                    }, 1)
                }
            }
            window.requestAnimationFrame(step)
        }, 100)
    }
    window.requestAnimationFrame(step)
}

///
/// nodejs has a problem with CanvasRenderingContext2D
///

//CanvasRenderingContext2D.prototype.roundRect = function (x, y, width, height, radius) {

//    this.beginPath();
//    this.moveTo(x + radius, y);
//    this.lineTo(x + width - radius, y);
//    this.quadraticCurveTo(x + width, y, x + width, y + radius);
//    this.lineTo(x + width, y + height - radius);
//    this.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
//    this.lineTo(x + radius, y + height);
//    this.quadraticCurveTo(x, y + height, x, y + height - radius);
//    this.lineTo(x, y + radius);
//    this.quadraticCurveTo(x, y, x + radius, y);
//    this.closePath();
//    this.fill();

//};
//CanvasRenderingContext2D.prototype.fillArrow = function (x, y, s, dir) {

//    if (s === "undefined") s = 3;

//    this.beginPath();
//    if (dir === 0) {
//        this.moveTo(x - s, y + s);
//        this.lineTo(x, y - s);
//        this.lineTo(x + s, y + s);
//        this.lineTo(x, y + s/2);
//    }
//    else if (dir === 1) {
//        this.moveTo(x - s, y - s);
//        this.lineTo(x + s, y);
//        this.lineTo(x - s, y + s);
//        this.lineTo(x - s / 2, y);
//    }
//    else if (dir === 2) {
//        this.moveTo(x - s, y - s);
//        this.lineTo(x, y + s);
//        this.lineTo(x + s, y - s);
//        this.lineTo(x, y - s / 2);
//    }
//    else if (dir === 3) {
//        this.moveTo(x + s, y - s);
//        this.lineTo(x - s, y);
//        this.lineTo(x + s, y + s);
//        this.lineTo(x + s / 2, y);
//    }
//    this.closePath();
//    this.fill();

//};

fabric.Canvas.prototype.fillArrow = function (ctx, x, y, s, dir) {

    if (s === "undefined") s = 3;

    ctx.beginPath();
    if (dir === 0) {
        ctx.moveTo(x - s, y + s);
        ctx.lineTo(x, y - s);
        ctx.lineTo(x + s, y + s);
        ctx.lineTo(x, y + s / 2);
    }
    else if (dir === 1) {
        ctx.moveTo(x - s, y - s);
        ctx.lineTo(x + s, y);
        ctx.lineTo(x - s, y + s);
        ctx.lineTo(x - s / 2, y);
    }
    else if (dir === 2) {
        ctx.moveTo(x - s, y - s);
        ctx.lineTo(x, y + s);
        ctx.lineTo(x + s, y - s);
        ctx.lineTo(x, y - s / 2);
    }
    else if (dir === 3) {
        ctx.moveTo(x + s, y - s);
        ctx.lineTo(x - s, y);
        ctx.lineTo(x + s, y + s);
        ctx.lineTo(x + s / 2, y);
    }
    ctx.closePath();
    ctx.fill();

};

fabric.Canvas.prototype.scrollbars = {};
fabric.Canvas.prototype.scrollbars.viewportwidth = 0;
fabric.Canvas.prototype.scrollbars.viewportheight = 0;

fabric.Canvas.prototype._drawScrollbars = function (ctx) {

    this.scrollbars.viewportwidth = ctx.canvas.clientWidth;
    this.scrollbars.viewportheight = ctx.canvas.clientHeight;

    if (!this._page) return;

    var pY = this.viewportTransform[5];
    var pX = this.viewportTransform[4];


    var yMin = -this._page.getBoundingRect().height;
    var yMax = ctx.canvas.clientHeight;
    if (pY < yMin) pY = yMin;
    if (pY > yMax) pY = yMax;
    var posRelativeY = (pY - yMin) / (yMax - yMin);

    var x = ctx.canvas.clientWidth - 17;
    var h = ctx.canvas.clientHeight / 3;
    var hF = ctx.canvas.clientHeight;
    var y = (ctx.canvas.clientHeight - h) * posRelativeY;
    var w = 17;
    ctx.fillStyle = "#3c3c3d";
    ctx.fillRect(x, 0, w, hF, 5, true);
    ctx.fillStyle = "#303032";
    ctx.fillRect(x, 19, w, 17, 5, true);
    ctx.fillRect(x, ctx.canvas.clientHeight - 19, w, 17, 5, true);
    ctx.fillStyle = "#949494"
    this.fillArrow(ctx, x + 19 / 2, 28, 4, 0);
    this.fillArrow(ctx, x + 19 / 2, ctx.canvas.clientHeight - 10, 4, 2);
    ctx.fillRect(x, y, w, h);


    var xMin = -this._page.getBoundingRect().width;
    var xMax = ctx.canvas.clientWidth;
    if (pX < xMin) pX = xMin;
    if (pX > xMax) pX = xMax;
    var posRelativeX = (pX - xMin) / (xMax - xMin);

    w = h;
    x = (ctx.canvas.clientWidth - w) * posRelativeX;
    y = ctx.canvas.clientHeight - 18;
    h = 18;
    ctx.fillStyle = "#3c3c3d";
    ctx.fillRect(0, ctx.canvas.clientHeight - 18, ctx.canvas.clientWidth - 17, hF, 5, true);
    ctx.fillStyle = "#303032";
    ctx.fillRect(19, ctx.canvas.clientHeight - 18, 17, 17, 5, true);
    ctx.fillRect(ctx.canvas.clientWidth - 34, ctx.canvas.clientHeight - 18, 17, 17, 5, true);

    ctx.fillStyle = "#949494"
    ///ctx.roundRect(x, y, w, h, 5, true);
    this.fillArrow(ctx, 28, y + 8, 4, 3);
    this.fillArrow(ctx, ctx.canvas.clientWidth - 25, y + 8, 4, 1);
    ctx.fillRect(x, y, w, h);

    var debug = false;
    if (debug) {
        ctx.fillStyle = "#c00"
        ctx.fillText(posRelativeX + " " + pX + " " + pY, 30, 30);
    }
}


fabric.Canvas.prototype.grid = false
fabric.Canvas.prototype.gridSize = 15

fabric.Canvas.prototype._drawGridLines = function (ctx) {
    if (!this.grid) return
    let pageSize = this._page ? this._page.width : this.width
    let gridSize = this.gridSize * pageSize / 100
    let i = this.getZoom(),
        r = gridSize * i,
        n = r - this.viewportTransform[4] % r,
        o = r - this.viewportTransform[5] % r,
        s = this.width,
        a = this.height,
        /// centerPageY = this._page.getBoundingRect().top + (this._page.getBoundingRect().height / 2),
        /// centerPageX = this._page.getBoundingRect().left + (this._page.getBoundingRect().width / 2)
        centerPageY = this.viewportTransform[5] + (this._page.getBoundingRect().height / 2),
        centerPageX = this.viewportTransform[4] + (this._page.getBoundingRect().width / 2)

    ctx.fillStyle = "#b6b6b6"
    //draw vertical
    for (let y = -o; y <= a + o; y += r) {
        ctx.fillRect(0, y, s, 0.5);
    }
    //draw horizontal
    for (let x = -n; x <= s + n; x += r) {
        ctx.fillRect(x, 0, 0.5, a);
    }
    ctx.fillStyle = "#949494"
    ctx.fillRect(centerPageX, 0, 2, a)
    ctx.fillRect(0, centerPageY, s, 2);
}

fabric.Canvas.prototype.renderCanvas = function (ctx, objects) {
    var v = this.viewportTransform,
        path = this.clipPath;
    this.cancelRequestedRender();
    this.calcViewportBoundaries();
    this.clearContext(ctx);
    fabric.util.setImageSmoothing(ctx, this.imageSmoothingEnabled);
    this.fire('before:render', {
        ctx: ctx,
    });
    this._renderBackground(ctx);
    ctx.save();
    //apply viewport transform once for all rendering process
    ctx.transform(v[0], v[1], v[2], v[3], v[4], v[5]);
    this._renderObjects(ctx, objects);
    ctx.restore();
    if (!this.controlsAboveOverlay && this.interactive) {
        this.drawControls(ctx);
    }
    if (path) {
        path.canvas = this;
        // needed to setup a couple of variables
        path.shouldCache();
        path._transformDone = true;
        path.renderCache({
            forClipping: true
        });
        this.drawClipPathOnCanvas(ctx);
    }
    this._renderOverlay(ctx);
    this._drawGridLines(ctx);
    this._drawHelperLines(ctx);
    this._drawScrollbars(ctx);

    if (this.controlsAboveOverlay && this.interactive) {
        this.drawControls(ctx);
    }
    this.fire('after:render', {
        ctx: ctx,
    });
}

fabric.Canvas.prototype.positionActiveObject = function (position) {
    let activeObject = this.getActiveObject()
    if (!activeObject) return
    switch (position) {
        case "front":
            this.bringToFront(activeObject)
            break
        case "back":
            this.sendToBack(activeObject)
            break
        case "up":
            this.bringForward(activeObject)
            break
        case "down":
            this.sendBackwards(activeObject)
            break
    }
    if (this._objects.indexOf(this._page) !== 0) {
        this._objects.splice(this._objects.indexOf(this._page), 1)
        this._objects.unshift(this._page)
        this._page = this._objects[0]
    }
}
fabric.Canvas.prototype.alignActiveObject = function (e) {
    let i = this.getActiveObject()
    if (!i)
        return console.info("No active selection")
    if (i.type === 'activeSelection') {
        let selection = this.getActiveObject().getBoundingRect(true, true)
        let objects = this.getActiveObject()._objects
        objects.forEach(object => {
            if (e === 'left') {
                object.set({
                    left: -selection.width / 2
                })
            }
            if (e === 'center') {
                object.set({
                    left: -object.getBoundingRect(true, true).width / 2
                })
            }
            if (e === 'right') {
                object.set({
                    left: selection.width / 2 - object.getBoundingRect(true, true).width
                })
            }
        })
    } else {
        let r = this._page
        let o = i.getBoundingRect(),
            s = o.height / this.viewportTransform[3],
            a = o.width / this.viewportTransform[0]
        switch (e) {
            case "top":
                i.setPositionByOrigin(new fabric.Point(i.getCenterPoint().x, s / 2 + r.getCenterPoint().y - r.height / 2), "center", "center")
                break
            case "middle":
                i.setPositionByOrigin({
                    x: i.getCenterPoint().x,
                    y: r.getCenterPoint().y
                }, "center", "center")
                break
            case "bottom":
                i.setPositionByOrigin(new fabric.Point(i.getCenterPoint().x, r.getCenterPoint().y + r.height / 2 - s / 2), "center", "center")
                break
            case "left":
                i.setPositionByOrigin(new fabric.Point(r.getCenterPoint().x - r.width / 2 + a / 2, i.getCenterPoint().y), "center", "center")
                break
            case "center":
                i.setPositionByOrigin({
                    x: r.getCenterPoint().x,
                    y: i.getCenterPoint().y
                }, "center", "center")
                break
            case "right":
                i.setPositionByOrigin(new fabric.Point(r.getCenterPoint().x + r.width / 2 - a / 2, i.getCenterPoint().y), "center", "center")
        }
    }
}
fabric.Canvas.prototype.initKeyEvents = function () {
    fabric.util.addListener(document.body, 'keyup', event => {
        if (event.key === 'Delete') {
            if (!this.getActiveObject()) {
                return
            }
            if (this.getActiveObject().isEditing) {
                return
            }
            this.remove(...this.getActiveObjects())
            this.discardActiveObject()
            this.requestRenderAll()
        }

        if (event.ctrlKey && event.keyCode === 86) {
            // ctrl + v
            if (this.copiedObject === null) {
                return;
            }
            this.cloneSelections(this.copiedObject);
        }
    })

    fabric.util.addListener(document.body, 'keydown', this._onKeyDown.bind(this))
}

fabric.Canvas.prototype._onKeyDown = function (e) {
    let activeObject = this.getActiveObject();
    if (!activeObject || (activeObject.type === 'InteractiveText' && activeObject.isEditing) || e.target.className === 'el-input__inner') {
        return
    }
    const bigStep = 40,
        mediumStep = 20,
        smallStep = 5

    let step = e.shiftKey ? bigStep : (e.altKey ? mediumStep : smallStep);

    if (e.keyCode === 40 || e.keyCode === 38) {
        // shift/alt/without key + down/up
        activeObject.set({
            top: activeObject.top + (e.keyCode === 40 ? step : -step)
        });
    } else if (e.keyCode === 37 || e.keyCode === 39) {
        // shift/alt/without key + left/rigth
        activeObject.set({
            left: activeObject.left + (e.keyCode === 39 ? step : -step)
        });
    } else if (e.ctrlKey && e.keyCode === 67) {
        // ctrl + c
        this.copiedObject = activeObject;
    }
    activeObject.setCoords();
    this.fire('object:moving', { target: activeObject });
}

fabric.Canvas.prototype.helperLine = false;
fabric.Canvas.prototype.paddingDistanse = 0;
fabric.Canvas.prototype.marginDistanse = 0;
fabric.Canvas.prototype.pxPerCm = 1;
fabric.Canvas.prototype._drawHelperLines = function (ctx) {
    //console.log("_drawHelperLines");
    if (!this.helperLine || (this.paddingDistanse === 0 && this.marginDistanse === 0)) return;
    let scale = this.pxPerCm * this.getZoom()
    if (this.paddingDistanse > 0) {
        let dist = this.paddingDistanse * scale
        this._setBorderSquare(ctx, dist, '#022EF2', true);
    }
    if (this.marginDistanse > 0) {
        let dist = -this.marginDistanse * scale;
        this._setBorderSquare(ctx, dist, '#EF0808', true);
    }
    /// draw page border
    this._setBorderSquare(ctx, 0, '#555555', false);
}

fabric.Canvas.prototype._setBorderSquare = function (ctx, distanse, color, dashed) {
    let r = this._page.getBoundingRect();
    r.left = this.viewportTransform[4];
    r.top = this.viewportTransform[5];
    r.top += distanse
    r.left += distanse
    r.width -= 2 * distanse
    r.height -= 2 * distanse
    ctx.lineWidth = 1;
    ctx.setLineDash([]);
    if (dashed)
        ctx.setLineDash([15, 5]);
    ctx.strokeStyle = color;
    ctx.strokeRect(r.left, r.top, r.width, r.height);
}

fabric.Canvas.prototype.changeObjectDimensions = function (newDimensions) {
    let activeObject = this.getActiveObject();
    if (!activeObject) {
        return
    }

    activeObject.set(newDimensions);
    this.renderAll();
}


fabric.Canvas.prototype.copiedObject = null;

fabric.Canvas.prototype.cloneSelections = function (object = null) {
    let activeObject = object === null ? this.getActiveObject() : object;
    if (!activeObject) {
        return
    }

    activeObject.clone((cloned) => {
        cloned.clone((clonedObj) => {
            this.discardActiveObject()
            clonedObj.set({
                left: clonedObj.left + 50,
                top: clonedObj.top - 50,
                evented: true,
            })
            if (clonedObj.type === 'activeSelection') {
                clonedObj.canvas = this;
                clonedObj.forEachObject((obj) => {
                    this.add(obj)
                });
                clonedObj.setCoords();
            } else {
                this.add(clonedObj)
            }
            cloned.top -= 50;
            cloned.left += 50;
            this.setActiveObject(clonedObj);
            this.requestRenderAll();
        });
    });
}

fabric.Canvas.prototype.toSVG2 = function () {
    let svg = this.toSVG({
        viewBox: {
            x: this._page.left,
            y: this._page.top,
            width: this._page.width * this._page.scaleX,
            height: this._page.height * this._page.scaleY
        },
        width: this._page.width * this._page.scaleX,
        height: this._page.height * this._page.scaleY
    })
    return svg;
}


module.exports = fabric