<template>
    <div id="app">
        <Editor json="App" />
    </div>
</template>

<script>
import Editor from './components/Editor.vue'

export default {
  name: 'App',
  components: {
    Editor
  }
}

window.ae = {};
window.ae.send = function(d) { if (window.parent) { window.parent.postMessage(d, '*'); } }
</script>

<style>
    body { margin: 0px; padding: 0px; overflow: hidden; }
    #app { font-family: 'Open Sans', sans-serif; }
</style>
